import { LocalStorage } from "@/utils/localstorage";
import axios from "axios";
import type { LoginCredentials, SignUpCredentials } from "../types/index"; 
 
export class API {
    baseUrl: string 
    constructor() {
        this.baseUrl = process.env.VUE_APP_API_URL;
    }
    async get(url: string) {
        const response = await axios(this.baseUrl + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + LocalStorage.get('token')
            }
        });
        return response.data;
    }
   async post(url: string, data: any, options: any = {}) {
        const response = await axios(this.baseUrl + url, {
            method: 'POST',

            headers: {
                'Content-Type': !Object.prototype.hasOwnProperty.call(options, 'isFormData') ? 'application/json' : 'multipart/form-data',
                'Accept': !Object.prototype.hasOwnProperty.call(options, 'isFormData') ? 'application/json' : 'multipart/form-data',
                'Authorization': 'Bearer ' + LocalStorage.get('token')
            },
            data: data
        });
        return response.data;
    }
    async logIn (credentials: LoginCredentials) { 
        return await this.post('auth', credentials, {isFormData: true})
    }
    // add new user
    async register (signUpCredentials: SignUpCredentials ){
        return await this.post('user/create', signUpCredentials)
    }
    // get user info
    async getUserData(){
        return await this.get('user/info')
    }
}