
import { API } from '@/api/api';
import { Group, Goods } from '@/types/goods';
import { debugErrorSnackbar } from '@/utils/snackbars';
const api = new API();

interface State {
    allServices: Goods[],
    allServiceGroups: Group[],
    loading: boolean
}
interface Context {
    state: State;
    commit: (mutation: string, payload?: any) => void;
    dispatch: (action: string, payload?: any) => any;
}

const initialState: State = {
    allServices: [],
    allServiceGroups: [],
    loading: false
}
const handleError = (e: any) => {
    return { status: "error", message: e };
}

export const servicesService = {
    namespaced: true,
    state: initialState,
    actions: {
        async loadAll({ commit, dispatch }: Context) {
            commit('setLoading', true);
            try {
                const [goods, groups] = await Promise.all([
                    dispatch('getAllServices'),
                    dispatch('getAllServiceGroups')
                ]);
            } catch (e) {
                debugErrorSnackbar(e);
                return handleError(e);
            }
            commit('setLoading', false);
        },
        async getAllServices({ commit }: Context) {
            try {
                const resp = await api.getGoodsList();
                if (resp) {
                    commit('setAllServices', resp);
                    return { status: "success", data: resp };
                }
                return { status: "error", message: resp };
            } catch (e) {
                return handleError(e);
            }
        },
        async getAllServiceGroups({ commit }: Context) {
            try {
                const resp = await api.getGoodsGroups();
                if (resp) {
                    commit('setAllServiceGroups', resp);
                    return { status: "success", data: resp };
                }
                return { status: "error", message: resp };
            } catch (e) {
                debugErrorSnackbar(e);
                return handleError(e);
            }
        },
        async addGoods({ dispatch }: Context, payload: Goods) {
            try {
                const resp = await api.addGoods(payload);
                if (resp) {
                    dispatch('getAllServices');
                    return { status: "success", data: resp };
                }
                return { status: "error", message: resp };
            } catch (e) {
                debugErrorSnackbar(e);
                handleError(e);
            }
        },
        async addGoodsGroup({ dispatch }: Context, payload: Group) {
            try {
                const resp = await api.addGoodsGroup(payload);
                if (resp) {
                    dispatch('getAllServiceGroups');
                    return { status: "success", data: resp };
                }
                return { status: "error", message: resp };
            } catch (e) {
                debugErrorSnackbar(e);
                handleError(e);
            }
        },
        async editGoods({ dispatch }: Context, payload: Goods) {
            try {
                const resp = await api.editGoods(payload);
                if (resp) {
                    dispatch('getAllServices');
                    return { status: "success", data: resp };
                }
                return { status: "error", message: resp };
            } catch (e) {
                debugErrorSnackbar(e);
                handleError(e);
            }
        }
    },
    mutations: {
        setLoading(state: State, loadingState: boolean) {
            state.loading = loadingState;
        },
        setAllServices(state: State, payload: Goods[]) {
            state.allServices = payload;
        },
        setAllServiceGroups(state: State, payload: Group[]) {
            state.allServiceGroups = payload;
        }
    },
    getters: {
        selectAllServices(state: State): Goods[] {
            return state.allServices;
        },
        selectAllServiceGroups(state: State): Group[] {
            return state.allServiceGroups;
        },
        loading(state: State): boolean {
            return state.loading;
        },
        selectServiceByGroupId(state: State) {
            return (groupId: string) => {
                return state.allServices.filter((goods: Goods) => goods.group_id === parseInt(groupId));
            }
        },
        selectServiceById(state: State) {
            return (goodsId: string) => {
                return state.allServices.find((goods: Goods) => goods.id === parseInt(goodsId));
            }
        },
        selectAllRequiredGroups(state: State) {
            return state.allServiceGroups.filter((group: Group) => group.order_required);
        }
    }

}