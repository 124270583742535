interface Token {
    access_token: string;
    expires_in: number;
}
export class LocalStorage{
    //set new token and expired date
    static set(key: string, value: any){
        const sevenDaysAfter = new Date().getTime() + (1000 * 60 * 60 * 24 * 7)
        const token = {
            access_token: value,
            expires_in: sevenDaysAfter
        }
        localStorage.setItem(key, JSON.stringify(token));
    }
    //get token from localstorage and remove it if its expired
    static get(key: string){
        try{
            const token = localStorage.getItem(key);
            if (token) {
                if (JSON.parse(token).expires_in > new Date().getTime()) {
                    return JSON.parse(token).access_token;
                }
                else {
                    this.remove(key);
                    return null;
                }
            }return null;
        //if token is in old format or have proplem for parse token, remove it
        }catch(e){
            console.log('Token is not valid, remove it')
            this.remove(key);
        }
    }
    //remove token from localstorage
    static remove(key: string){
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
    }
    
}