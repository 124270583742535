
import { debugErrorSnackbar } from '@/utils/snackbars';

interface State {
   currentView: string;
   selectedDate: Date;
}

interface Context {
    state: State;
    rootGetters: any;
    commit: (mutation: string, payload?: any) => void;
    dispatch: (action: string, payload?: any) => any;
}
const initialState: State = {
   currentView: 'dayGridMonth',
    selectedDate: new Date(),
}
export default {
    namespaced: true,
    state: initialState,
    mutations: {
        setCurrentView(state: State, payload: string) {
            state.currentView = payload;
        },
        setSelectedDate(state: State, payload: Date) {
            state.selectedDate = payload;
        }
    },
    actions: {
        
    },

    getters: {
        selectCurrentView(state: State) {
            return state.currentView;
        },
        selectSelectedDate(state: State) {
            return state.selectedDate;
        }
    },
}