import store from "@/store";

// if have token, try to preload data
export const preloadRequiredData =  () => {
    const token = store.getters["auth/selectToken"];
    if (!token) {
      return;
    }
    store.dispatch("getSettings");
    store.dispatch("auth/getUserData");
    store.dispatch("usersService/getAllUsers");
    store.dispatch("shiftService/getShift");
    store.dispatch("loftService/getLofts");
    store.dispatch("officeService/getOffices");
    store.dispatch("servicesService/loadAll");
    store.dispatch("getGlossary");
  };