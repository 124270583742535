
import { API } from '@/api/api';
import { LocalStorage } from '@/utils/localstorage';
import { Loft } from '@/types/lofts';
import { debugErrorSnackbar } from '@/utils/snackbars';
const api = new API();
interface State {
    lofts: Loft[],
    loading: boolean
    loadLoftsImages: boolean,
    loftsImages: any

}
interface Context {
    state: State;
    commit: (mutation: string, payload?: any) => void;
    dispatch: (action: string, payload?: any) => any;
}
const initialState: State = {
    lofts: [],
    loadLoftsImages: false,
    loftsImages: [],
    loading: false
}
export const loftService = {
    namespaced: true,

    state: initialState,
    mutations: {
        setLofts(state: State, payload: any[]) {
            state.lofts = payload;
        },
        setLoading(state: State, loading: boolean) {
            state.loading = loading;
        },
        pushNewLoft(state: State, payload: Loft) {
            state.lofts.push(payload);
        },
        setLoftsImages(state: State, payload: any[]) {
            state.loftsImages = payload;
        },
        setLoadLoftsImages(state: State, payload: boolean) {
            state.loadLoftsImages = payload;
        }
    },
    actions: {
        async getLofts({ commit }: Context,) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.getLoftsList().then((response: any) => {
                        if (response) {
                            commit('setLofts', response);
                            commit('setLoading', false);
                            resolve({ status: "success", data: response });
                        }
                    }).catch((error: any) => {
                        commit('setLoading', false);
                        debugErrorSnackbar(error);
                        reject({status:"error",message:error});
                    });
                });
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async createLoft({ commit }: Context, payload: Loft) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.addLoft(payload).then((response: Loft) => {
                        if (response) {
                            commit('setLoading', false);
                            commit('pushNewLoft', response);
                            resolve({ status: "success", data: response });
                        }

                    }).catch((error: any) => {
                        commit('setLoading', false);
                        debugErrorSnackbar(error);
                        reject({status:"error",message:error});
                    });
                });
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async uploadLoftImage({ commit }: Context, formData: any) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.uploadLoftImage(formData).then((response: any) => {
                        if (response) {
                            commit('setLoading', false);
                            resolve({ status: "success", data: response });
                        }
                    }).catch((error: any) => {
                        commit('setLoading', false);
                        reject({status:"error",message:error});
                    });
                });
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async editLoft({ commit, dispatch }: Context, payload: Loft) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.editLoft(payload).then((response: Loft) => {
                        if (response) {
                            commit('setLoading', false);
                            resolve({ status: "success", data: response });
                            // update the list of lofts
                            dispatch('getLofts');
                        }
                    }).catch((error: any) => {
                        commit('setLoading', false);
                        reject({status:"error",message:error});
                    });
                });
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async getLoftPrices({ commit }: Context, loft_id: any) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.getLoftPrices(loft_id).then((response: any) => {
                        if (response) {
                            commit('setLoading', false);
                            resolve({ status: "success", data: response });
                        }
                    }).catch((error: any) => {
                        commit('setLoading', false);
                        reject({status:"error",message:error});
                    });
                });
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async saveLoftExtraPrice({ commit,dispatch }: Context, payload: any) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.saveLoftExtraPrice(payload).then((response: any) => {
                        if (response) {
                            commit('setLoading', false);
                            resolve({ status: "success", data: response });
                        }
                    }).catch((error: any) => {
                        commit('setLoading', false);
                        reject({status:"error",message:error,data: []});
                    });
                });
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async cloneLoftExtraPrices({ commit, dispatch }: Context, payload: { from_loft_id: number, to_loft_id: number }) {
            try {
                const { from_loft_id, to_loft_id } = payload;
                if (!from_loft_id || !to_loft_id) {
                    return { status: "error", data: [] };
                }
                // Check if the two IDs are the same
                if (from_loft_id === to_loft_id) {
                    return { status: "error", data: [] };
                }
                // Get the parent loft prices
                const { status, data: parentLoftExtraPricesData } = await dispatch('getLoftPrices', from_loft_id);
                if (status !== "success") {
                    return { status: "error", data: [] };
                }
                const { status: statusToLoft, data: targetLoftCurrentExtraPricesData } = await dispatch('getLoftPrices', to_loft_id);
                if (statusToLoft !== "success") {
                    return { status: "error", data: [] };
                }
                // Delete the current target loft prices
                console.log(targetLoftCurrentExtraPricesData)
                for (let i = 0; i < targetLoftCurrentExtraPricesData.length; i++) {
                    const record_id = targetLoftCurrentExtraPricesData[i].id;
                    await dispatch('deleteLoftExtraPrice', record_id);
                }
                // Save the parent loft prices to the target loft
                commit('setLoading', true);
                const response = await api.saveLoftExtraPrices(to_loft_id, parentLoftExtraPricesData);
                commit('setLoading', false);
                return { status: "success", data: response };
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async deleteLoftExtraPrice({ commit }: Context, record_id: any) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.deleteLoftExtraPrice(record_id).then((response: any) => {
                        if (response) {
                            commit('setLoading', false);
                            resolve({ status: "success", data: response });
                        }
                    }).catch((error: any) => {
                        commit('setLoading', false);
                        reject({status:"error",message:error});
                    });
                });
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        }

    },
    getters: {
        selectLofts: (state: State) => state.lofts,
        selectLoftById: (state: State) => (id: number) => {
            return state.lofts.find((loft: Loft) => loft.id === id);
        },
        selectLoftImageById: (state: State) => (id: number) => state.loftsImages[id] ? state.loftsImages[id] : null,
        loading: (state: State) => state.loading,
    },
}