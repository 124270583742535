
import { API } from '@/api/api';
import { Office } from '@/types/offices'; 
import { debugErrorSnackbar } from '@/utils/snackbars';
const api = new API();

interface State {
    offices: Office[],
    loading: boolean
}

interface Context {
    state: State;
    commit: (mutation: string, payload?: any) => void;
    dispatch: (action: string, payload?: any) => any;
}

const initialState:State  = {
    offices: [],
    loading: false
}
export const officeService = {
    namespaced: true,
    state: initialState,
    mutations: {
        setOffices(state:State, payload: Office[]) {
            state.offices = payload;
        },
        addOfficeToList(state:State, payload: Office) {
            state.offices.push(payload);
        },
        setLoading(state:State, loading: boolean) {
            state.loading = loading;
        }
    },
    actions: {
        async getOffices({commit}:Context) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.getOfficesList().then((response: Office[]) => {
                        if (response) {
                            commit('setOffices', response);
                            commit('setLoading', false);
                            resolve({status:"success", data:response});
                        }
                    }).catch((error: any) => {
                        commit('setLoading', false);
                        reject({status:"error", message:error});
                    });
                });
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async addNewOffice({commit}:Context, payload: Office) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.addOffice(payload).then((response: Office) => {
                        if (response) {
                            commit('setLoading', false);
                            commit('addOfficeToList', response)
                            resolve({status:"success", data:response});
                        }
                    }).catch((error: any) => {
                        commit('setLoading', false);
                        reject({status:"error", message:error});
                    });
                });
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        }
    },
    getters: {
        selectOffices: (state:State) => {
            // sort by name first letter
            return state.offices.sort((a:Office, b:Office) =>  a.name.localeCompare(b.name));
        },
        selectOfficeById: (state:State) => (id: number) => {
            return state.offices.find(office => office.id === id);
        },
        loading: (state:State) => state.loading,
    },
}