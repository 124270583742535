interface InitialState {
    message: string,
    color: string,
    isSnackbar: boolean,
    showCloseButton: boolean,
}
interface Context {
    commit: (mutation: string, payload?: any) => void;
}
const initialState:InitialState = {
    message: '',
    color: 'white',
    isSnackbar: false,
    showCloseButton: true,
}
export const snackbarService = {
    namespaced: true,
    state: initialState,
    mutations: {
        setData(state:any, payload: {message: string, color: string, timeout: number}) {
            state.message = payload.message;
            state.color = payload.color;
            state.isSnackbar = true;
        },
        setSnackbar(state:any, payload: boolean) {
            state.isSnackbar = payload;
        },
    },
    actions: {
        async showSnackbar({commit}:Context, payload: {message: string, color: string , timeout?: number}) {
            commit('setData', payload);
            !payload.timeout ? payload.timeout = 3000 : payload.timeout;
            await new Promise(resolve => setTimeout(resolve, payload.timeout));
            commit('setSnackbar', false)
        }
    },
    getters: {
        selectSnackbar: (state:any) => state.isSnackbar,
        selectMessage: (state:any) => state.message,
        selectShowCloseButton: (state:any) => state.showCloseButton,
        selectColor: (state:any) => state.color,
    }
}