
import { API } from '@/api/api';
import { debugErrorSnackbar } from '@/utils/snackbars';

const api = new API();
interface Context {
    commit: (mutation: string, payload?: any) => void;
    dispatch: (action: string, payload?: any) => any;
}
interface User {
    id: number,
    login: string,
    name: string,
    roles: string[],
    office_id: number | null,
    permissions: string[],
}

interface State {
    users: User[] | null,
    loading: boolean
}
const initialState: State = {
    users: null,
    loading: false
}
export const usersService = {
    namespaced: true,
    state: initialState,
    mutations: {
        setUsers(state: State, payload: User[]) {
            state.users = payload;
        },
        setLoading(state: State, loading: boolean) {
            state.loading = loading;
        }
    },
    actions: {
        getAllUsers({ commit, dispatch }: Context) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.getUsersList().then((response: User[]) => {
                        if (response) {
                            commit('setUsers', response);
                            resolve({ status: "success", data: response });
                        }
                    }).catch((error: any) => {
                        debugErrorSnackbar(error);
                        reject({ status: "error", message: error });
                    });
                    commit('setLoading', false);
                })
            } catch (error: any) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                return { status: "error", message: error };
            }
        },
        editUserData({ commit, dispatch }: Context, payload: User) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    api.editUser(payload).then((response: any) => {
                        if (response) {
                            resolve({ status: "success", data: response });
                        }
                    }).catch((error: any) => {
                        debugErrorSnackbar(error);
                        reject({ status: "error", message: error });
                    });
                    commit('setLoading', false);
                })
            } catch (error: any) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                return { status: "error", message: error };
            }
        },
    },
    getters: {
        selectAllUsers: (state: State) => state.users,
        loading: (state: State) => state.loading,
        selectUserById: (state: State) => (id: number) => {
            if (state.users) {
                return state.users.find(user => user.id == id);
            }
        },
        userLoginExists: (state: State) => (login: string) => {
            if (state.users) {
                const result =  state.users.find(user => user.login == login);
                if (result) return true;
            }return false
        }

    

    },
}