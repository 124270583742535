import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
export const vuetify:any = createVuetify({
    components,
    directives,
    // @ts-ignore
    icons: {
      defaultSet: 'mdi', // This is already the default value - only for display purposes
    },
    theme: {
      light: true,
        themes: {
          light: {
            // @ts-ignore
            background: '#54595F', // Not automatically applied
            
          },
          dark: {
            // @ts-ignore
            background: '#54595F', // If not using lighten/darken, use base to return hex
        
          },
        },
      },
})