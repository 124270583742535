import { createStore } from 'vuex'
import { API } from '../api/api'
import { loftService } from './modules/loftService'
import { snackbarService } from './modules/snackbarService'
import { officeService } from './modules/officeService'
import { usersService } from './modules/usersService'
import { clientsService } from './modules/clientsService'
import { servicesService } from './modules/servicesService'
import { shiftService } from './modules/shiftService'
import { debugErrorSnackbar } from '../utils/snackbars'
const api = new API();

interface MainState {
  loading: boolean,
  glossary: any,
  settings: any,
  mainFilter: any,
  autoUpdateInterupted: boolean,
}
const initialState: MainState = {
  loading: false,
  glossary: null,
  settings: null,
  autoUpdateInterupted: false,
  mainFilter: {
    filterByClient: null,
    search: null,
    selectedClient: null,
    showCleintFilter: false,
    
  },
}
const defaultOrderActiveStatuses = [ "viewed",
"working",
"checking",
"accepted",
"view",
"created",
"paying"];

export default createStore({
  state: initialState,
  getters: {
    selectAvailableRoles: (state: MainState) => state.glossary?.roles,
    selectOrderStatuses: (state: MainState) => state.glossary?.order_statuses,
    getStatusByCode: (state: MainState) => (code: string) => code && state.glossary?.order_statuses && state.glossary?.order_statuses[code] ? state.glossary?.order_statuses[code] : "Неизвестный статус",
    selectOrderTypes: (state: MainState) => state.glossary?.order_types,
    selectAvailableDiscounts: (state: MainState) => state.glossary?.order_discount_types ? Object.values(state.glossary?.order_discount_types) : [],
    selectGlossaryLoading: (state: MainState) => state.loading,
    selectOrderSources: (state: MainState) => state.glossary?.order_sources,
    // select order active statuses list
    selectActiveStatuses: (state: MainState) => state.settings?.active_statuses || defaultOrderActiveStatuses,
    selectMainFilter: (state: MainState) => state.mainFilter,
    selectRejectReasons: (state: MainState) => state.glossary?.reject_reasons,
    isAutoUpdateInterupted: (state: MainState) => state.autoUpdateInterupted,
  },
  mutations: {
    setAutoUpdateInterupted(state: any, val: boolean) {
      state.autoUpdateInterupted = val;
    },
    setGlossary(state: any, payload: any) {
      state.glossary = payload;
    },
    setSettings(state: any, payload: any) {
      state.settings = payload;
    },
    setLoading(state: any, loading: boolean) {
      state.loading = loading;
    },
   
    resetMainFilter(state: any) {
      state.mainFilter = {
        filterByClient: null,
        search: null,
        selectedClient: null,
        showCleintFilter: false,
        showAlreadyPayed: false,
      }
    },
    setMainFilter(state: any, payload: any) {
      for (const key in payload) {
        state.mainFilter[key] = payload[key];
      }
    }
  },
  actions: {
    getGlossary({ commit }: any) {
      commit('setLoading', true);
      try {
        return new Promise((resolve, reject) => {
          api.getGlossary().then((response: any) => {
            if (response) {
              commit('setGlossary', response);
              commit('setLoading', false);
              resolve(response);
            }
          }).catch((error: any) => {
            commit('setLoading', false);
            reject(error);
          });
        });
      }
      catch (error: any) {
        debugErrorSnackbar(error);
        commit('setLoading', false);
        return { status: "error", message: error };
      }
    },
    getSettings({ commit }: any) {
      commit('setLoading', true);
      try {
        return new Promise((resolve, reject) => {
          api.getSettings().then((response: any) => {
            if (response) {
              commit('setSettings', response);
              commit('setLoading', false);
              resolve(response);
            }
          }).catch((error: any) => {
            commit('setLoading', false);
            reject(error);
          });
        });
      }
      catch (error: any) {
        debugErrorSnackbar(error);
        commit('setLoading', false);
        return { status: "error", message: error };
      }
    }
  },
  modules: {
    usersService,
    snackbarService,
    officeService,
    loftService,
    clientsService,
    servicesService,
    shiftService
  }
})
