
import { API } from '../api/api';
import { LocalStorage } from '@/utils/localstorage';
import { debugErrorSnackbar } from '@/utils/snackbars';

const moduleApi = new API();

interface User {
    id: number,
    name: string,
    email: string,
    password: string,
    created_at: string,
    role: string
    permissions: string[]
}

interface State {
    user: User | null,
    token: string | null,
    error: string | null,
    loading: boolean
}
const initialState:State  = {
    user: null,
    token: LocalStorage.get('token'),
    error: null,
    loading: false
}
export default{
    namespaced: true,
    state: initialState,
    mutations: {
        setUser(state:State, user: User) {
            state.user = user;
        },
        setToken(state:State, token: string) {
            state.token = token;
        },
        setError(state:State, error: string) {
            state.error = error;
        },
        setLoading(state:State, loading: boolean) {
            state.loading = loading;
        }
    },
    actions: {
        async login({commit, dispatch}:any, credentials: {username: string, password: string}) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true);
                    commit('setError', null);
                    moduleApi.logIn(credentials)
                        .then((response: any) => {
                            if (response && response.access_token) {
                                const access_token: string = response.access_token;
                                LocalStorage.set('token', access_token);
                                commit('setToken', access_token);
                                commit('setLoading', false);
                                resolve({status:"success", data:response});
                            }
                        })
                        .catch((error: any) => {
                            commit('setError', error);
                            debugErrorSnackbar(error);
                            commit('setLoading', false);
                            reject({status:"error", message:error});
                        });
                })
            }catch (e: any) {
                commit('setError', e);
                commit('setLoading', false);
                debugErrorSnackbar(e);
            }
        },
        // delete tokens and clean store to initial state
        async logout({commit, dispatch}:any) {
            try {
                commit('setLoading', true);
                commit('setError', null);
                commit('setToken', null);
                LocalStorage.remove('token');
                dispatch('order/clean', null, {root: true});
                dispatch('shiftService/clean', null, {root: true});
                commit('setLoading', false);
                return ({status: 'success', 'message': 'Logged out'});
            }catch (e: any) {
                commit('setError', e);
                commit('setLoading', false);
                debugErrorSnackbar(e);
            }
        },
        getUserData({commit, dispatch}:any) {
            try {
                return new Promise((resolve, reject) => {
                    
                    commit('setLoading', true);
                    commit('setError', null);
                    moduleApi.getUserData().then((response: any) => {
                        if (response) {
                            commit('setUser', response);
                            commit('setLoading', false);
                            resolve({status:"success", data:response});
                        }
                    }).catch((error: any) => {
                        // if error for example token expired or not valid
                        LocalStorage.remove('token');
                        commit('setError', error);
                        commit('setLoading', false);
                        resolve({status:"error", message:error});
                    });
                })
            }catch (e: any) {
                commit('setError', e);
                commit('setLoading', false);
                debugErrorSnackbar(e);
            }
        },
        async register({commit, dispatch}:any, credentials : any) {
            try {
                return new Promise((resolve, reject) => {
                    moduleApi.register(credentials)
                        .then((response: any) => {
                            if (response) {
                                commit('setLoading', false);
                                resolve({status:"success", data:response});
                            }
                        })
                        .catch((error: any) => {
                            reject({status:"error", message:error});
                        });
                })
            }catch (e: any) {

                debugErrorSnackbar(e);
            }
        },
    },
    getters: {
        selectUser: (state:State) => state.user,
        selectUserPermissions: (state:State) => state.user ? state.user.permissions : null,
        selectToken: (state:State) => state.token,
        error: (state:State) => state.error,
        loading: (state:State) => state.loading,
        isLoggedIn: (state:State) => state.token !== null && !state.loading,
        selectUserID: (state:State) => state.user ? state.user.id : null
    },
}