const orderRoute = {
    path: '/order/:id',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ './view/OrderPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Заказ',
      showBackButton: true,
      permission: null,
      showId: true
    }
  }
const orderHistoryRoute = {
    path: '/order/:id/history',
    name: 'orderhistory',
    component: () => import(/* webpackChunkName: "order" */ './view/OrderHistory.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'История заказа',
      showBackButton: true,
      permission: null,
      showId: true
    
  }
}
export default (router: { addRoute: (arg0: { path: string; name: string; component: () => Promise<typeof import("*.vue")>; meta: { requiresAuth: boolean; pageName: string, showBackButton: boolean, permission:any, showId: boolean } }) => void }) => {
    router.addRoute(orderRoute)
    router.addRoute(orderHistoryRoute)
  }