import { LocalStorage } from "@/utils/localstorage";
import axios , {CancelTokenSource} from "axios";
import { Order, DiscountPayload } from "@/types/orders";
interface OrderListPayload {
   filter: {
    office_id?: number; 
    loft_id?: number;
    client_id?: number;
    responsible_id?: number;
    order_statuses?: string[] | null; 
    reservation_begin?: string;
    reservation_end?: string;
    value?: string;
    period_days?: number;
    }

    page_number: number;
    page_limit: number;
}
interface IOptions {
    isFormData?: boolean;
}

export class API {
    baseUrl: string
    cancelTokens: { [key: string]: CancelTokenSource } = {};

    constructor() {
        this.baseUrl = process.env.VUE_APP_API_URL;
        this.cancelTokens =  {};

    }
    cancelPreviousRequest(type: string) {
        if (this.cancelTokens[type]) {
            this.cancelTokens[type].cancel(`Canceled ${type} due to a new request`);
            delete this.cancelTokens[type];
        }
    }
    async get(url: string, options: any = {}) {
        const response = await axios(this.baseUrl + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + LocalStorage.get('token')
            }
        });
        return response.data;
    }
    async post(url: string, data: any, options: any = {}, requestType?: string) {
        if (requestType) {
            this.cancelPreviousRequest(requestType);
            this.cancelTokens[requestType] = axios.CancelToken.source();
        }

        try {
            const response = await axios(this.baseUrl + url, {
                method: 'POST',
                headers: {
                    'Content-Type': !Object.prototype.hasOwnProperty.call(options, 'isFormData') ? 'application/json' : 'multipart/form-data',
                    'Accept': !Object.prototype.hasOwnProperty.call(options, 'isFormData') ? 'application/json' : 'multipart/form-data',
                    'Authorization': 'Bearer ' + LocalStorage.get('token')
                },
                data: data,
                cancelToken: requestType ? this.cancelTokens[requestType].token : undefined
            });
            return response.data;
        } catch (error:any) {
            if (axios.isCancel(error)) {
                console.log('Request was cancelled:', error.message);
            } else {
                return error.response
            }
        }
    }
    async delete(url: string) {
        const response = await axios(this.baseUrl + url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + LocalStorage.get('token')
            }
        });
        return response.data;
    }

    // orders endpoints
    async getOrdersList(office_id: number | null = null, loft_id: number | null = null, order_statuses: string[] | null = null, client_id: number | null = null, responsible_id:number | null = null, value: string | null = null, period_days: number | null = null, page_number=1, page_limit=25) {
        const payload: OrderListPayload = {filter: {}, page_number, page_limit}
        if (office_id) payload['filter']['office_id'] = office_id
        if (loft_id) payload['filter']['loft_id'] = loft_id
        if (order_statuses) payload['filter']['order_statuses'] = order_statuses
        if (client_id) payload['filter']['client_id'] = client_id
        if (responsible_id) payload['filter']['responsible_id'] = responsible_id
        if (value) payload['filter']['value'] = value
        if (period_days) payload['filter']['period_days'] = period_days
        payload['page_number'] = page_number
        payload['page_limit'] = page_limit
        const response = await this.post('order/list', payload)
        return response
    }
    // orders endpoints
    async getOrdersListForCalendar(order_statuses: string[] | null = null,reservation_begin:string | null, reservation_end:string|null, page_number=1, page_limit=1500) {
        const payload: OrderListPayload = {filter: {}, page_number, page_limit}
        if (reservation_begin) payload['filter']['reservation_begin'] = reservation_begin
        if (reservation_end) payload['filter']['reservation_end'] = reservation_end
        // if (order_statuses) payload['filter']['order_statuses'] = order_statuses
        payload['page_number'] = page_number
        payload['page_limit'] = page_limit
        const response = await this.post('order/list', payload, {}, 'getOrdersListForCalendar')
        return response
    }
    async checkTimeAvailability(filter: { loft_id: number, reservation_begin: string, reservation_end: string }) {
        const response = await this.post('order/list', {filter,
            page_number: 1,
            page_limit: 50
        })
        return response
    }
    // create new order
    async createOrder(orderCredentials: Order) {
        const response = await this.post('order/create', orderCredentials)
        return response
    }
    async editOrder(orderCredentials: Order) {
        const response = await this.post('order/edit', orderCredentials)
        return response
    }
    async getOrderInfo(order_id: number | null = null) {
        const payload = order_id ? {order_id} : {}
        const response = await this.post(`order/info`, payload)
        return response
    }
    async getOrderGoods(order_id: number) {
        const response = await this.get(`order/services?order_id=${order_id}`)
        return response
    }
    // todo: add types
    async addOrderGoods(orderGoods: any, order_id: number) {
        const response = await this.post(`order/services?order_id=${order_id}`, orderGoods)
        return response
    }
    async getOrderHistory(order_id: number) {
        const response = await this.post(`order/history`,{
            order_id
        })
        return response
    }
    async getOrderReceipt(order_id: number) {
        return await this.get(`order/receipt?order_id=${order_id}`)
    }
    async regenerateReceipt(order_id: number) {
        return await this.get(`order/receipt?order_id=${order_id}&update_reservations=true`)
    }
    async setOrderDiscount(payload: DiscountPayload) {
        return await this.post(`order/discount`, payload)
    }
    async deleteOrderDiscount(order_id: number) {
        return await this.delete(`order/discount?record_id=${order_id}`)
    }


}