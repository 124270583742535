import { LocalStorage } from "@/utils/localstorage";
import axios, { CancelTokenSource } from 'axios';
import { Client, ClientFilter } from "@/types/clients";
import { Loft } from "@/types/lofts";
import { Goods, Group } from "@/types/goods";
import { Shift } from "@/types/shift";
import { Order } from "@/types/orders";
import { debugErrorSnackbar } from "@/utils/snackbars";
interface BookingCredentials {
    client_id: number,
    loft_id: string,
    comment: string,
    reservation_begin: string,
    reservation_end: string
}
interface BookingCredentialsResponse {
    id: number,
    creator_id: string,
    client_id: number,
    loft_id: number,
    agreement_id: string,
    comment: string,
    status: string,
    reservation_begin: string,
    reservation_end: string
}

interface OrderStatuses {
    (): Promise<unknown>
}

interface APIError {
    detail: any[];
}

export class API {
    baseUrl: string
    cancelTokens: { [key: string]: CancelTokenSource } = {};

    constructor() {
        this.baseUrl = process.env.VUE_APP_API_URL;
        this.cancelTokens =  {};

    }
    cancelPreviousRequest(type: string) {
        if (this.cancelTokens[type]) {
            this.cancelTokens[type].cancel(`Canceled ${type} due to a new request`);
            delete this.cancelTokens[type];
        }
    }
    async get(url: string, options: any = {}) {
        const response = await axios(this.baseUrl + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + LocalStorage.get('token')
            }
        });
        return response.data;
    }
    async post(url: string, data: any, options: any = {}, requestType?: string) {
        if (requestType) {
            this.cancelPreviousRequest(requestType);
            this.cancelTokens[requestType] = axios.CancelToken.source();
        }

        try {
            const response = await axios(this.baseUrl + url, {
                method: 'POST',
                headers: {
                    'Content-Type': !Object.prototype.hasOwnProperty.call(options, 'isFormData') ? 'application/json' : 'multipart/form-data',
                    'Accept': !Object.prototype.hasOwnProperty.call(options, 'isFormData') ? 'application/json' : 'multipart/form-data',
                    'Authorization': 'Bearer ' + LocalStorage.get('token')
                },
                data: data,
                cancelToken: requestType ? this.cancelTokens[requestType].token : undefined
            });
            return response.data;
        } catch (error:any) {
            if (axios.isCancel(error)) {
                console.log('Request was cancelled:', error.message);
            } else {
                debugErrorSnackbar(error);
            }
        }
    }
    async delete(url: string) {
        const response = await axios(this.baseUrl + url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + LocalStorage.get('token')
            },
        });
        return response.data;
    }

    // edit existing user
    async editUser(editCredentials: any) {
        const response = await this.post('user/edit', editCredentials)
        return response
    }

    // list of all users in system
    async getUsersList() {
        const response = await this.post('user/list', {})
        return response
    }
    async bookLoft(bookingCredentials: BookingCredentials) {
        const response = await this.post('bookings', bookingCredentials)
        return response
    }
  
    async getLoftsList() {
        const response = await this.post('loft/list', {})
        return response
    }
    async getLoftById(id: number) {
        const response = await this.post('loft/list', { id })
        return response
    }

    async addLoft(loftCredentials: Loft) {
        const response = this.post('loft/create', loftCredentials)
        return response
    }
    async editLoft(loftCredentials: Loft) {
        const response = this.post('loft/edit', loftCredentials)
        return response
    }

    async getLoftPrices(loft_id: number) {
        return await this.get(`loft/prices?loft_id=${loft_id}`)
    }
    // save new loft extra price
    async saveLoftExtraPrice(payload: any) {
        const loft_id = payload.loft_id
        return await this.post(`loft/price?loft_id=${loft_id}`, [payload])
    }
    // save new loft extra prices array (fro clone)
    async saveLoftExtraPrices(loft_id:number, payload: any[]) {
        return await this.post(`loft/price?loft_id=${loft_id}`, payload)
    }
    async editLoftExtraPrice(payload: {loft_id?:number, record_id: number, price: number}) {
        const loft_id = payload.loft_id
        return await this.post(`loft/price?loft_id=${loft_id}`, payload)
    }
    async deleteLoftExtraPrice(record_id: any) {
        return await this.delete(`loft/price?record_id=${record_id}`)
    }


    // upload new loft image to server
    async uploadLoftImage(payload: any) {
        const { loft_id, image } = payload;

        const formData = new FormData();
        formData.append('file', image, `loft${loft_id}.jpg`);
        formData.append('loft_id', loft_id);
        try {
            const response = await axios.post(`https://crm.loftforparty.ru:5000/loft/image?loft_id=${loft_id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                },
            });
        } catch (error) {
            console.log(error);
        }
    }

    async getOfficesList() {
        const response = await this.post('office/list', {})
        return response
    }

    async addOffice(officeCredentials: { name: string, address: string }) {
        const response = await this.post('office/create', officeCredentials)
        return response
    }

    async getClientsList(filter:ClientFilter | unknown) {
        const response = await this.post('client/list', filter, {}, 'getClientsList')
        return response
    }
    async addClient(clientCredentials: Client) {
        const response = await this.post('client/create', clientCredentials)
        return response
    }
    async editClient(clientCredentials: Client) {
        const response = await this.post('client/edit', clientCredentials)
        return response
    }
   async getClientInfo(client_id:any) {
        const response = await this.post('client/info', client_id)
        return response
    }
    // goods endpoints
    async getGoodsList() {
        const response = await this.post('services/list', {})
        return response
    }
    async addGoods(goodsCredentials: Goods) {
        return await this.post('services/create', goodsCredentials)
    }

    async addGoodsGroup(group: Group) {
        return await this.post('services/group', group)
    }
    async editGoods(goodsCredentials: Goods) {
        const response = await this.post('services/edit', goodsCredentials)
        return response
    }
    async getGoodsGroups() {
        const response = await this.get('services/groups', {})
        return response
    }
    async getGoodsInfo(id: number) {
        const response = await this.post('services/info', { id })
        return response
    }

    // shifts endpoints
    async getShift(office_id: number) {
        return await this.get(`shift/current?office_id=${office_id}`)
    }
    async openShift() {
        return await this.post('shift/open', {})
    }
    async closeShift() {
        return await this.post('shift/close', {})
    }
    async transferShift(shift: Shift): Promise<Shift | APIError> {
        return await this.post('shift/edit', shift)
    }
    async getShifts(params:any): Promise<Shift[] | APIError> {
        return await this.post('shift/list',
            params
        )
    }
    async getShiftOrders(shift_id: number): Promise<Order[] | APIError> {
        return await this.post('shift/orders', { shift_id })
    }

    async getGlossary() {
        return await this.get('info/glossary')
    }
    async getSettings(){
        return await this.get('info/settings')
    }

}





