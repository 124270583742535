import store from "@/store";

export const authErrorSnackbar = ():void => {
  store.dispatch('snackbarService/showSnackbar', { 
    message: 'Ошибка авторизации',
    color: 'error',
    timeout: 2000 });
};

export const logoutSuccessSnackbar = ():void => {
  store.dispatch('snackbarService/showSnackbar', {
    message: 'Вы вышли из системы',
    color: 'success',
    timeout: 2000 });
};

export const notValidSnackbar = ():void => {
    store.dispatch("snackbarService/showSnackbar", {
      message: "Проверьте правильность заполнения полей",
      color: "error",
    });
  };
  
 export const saveErrorSnackbar = (e:any = null):void => {
  let message = ''
  if (e){
    if (e.response) {
      message = e.response.data.detail
    } else if (e.message) {
      message = e.message
    } else {
      message = e
    }
  }
    store.dispatch("snackbarService/showSnackbar", {
      message: "Произошла ошибка:" + message,
      color: "error",
    });
  };

  export const saveSuccessSnackbar = ():void => {
    store.dispatch("snackbarService/showSnackbar", {
      message: "Сохранено",
      color: "success",
    });
  };
  export const imageUploadErrorSnackbar = (err:any):void => {
    store.dispatch("snackbarService/showSnackbar", {
      message: err.detail,
      color: "error",
    });
  }
  export const infoSnackbar = (message:any):void => {
    store.dispatch("snackbarService/showSnackbar", {
      message: message,
      color: "warning",
    });
  }
  export const imageUploadSuccessSnackbar = ():void => {
    store.dispatch("snackbarService/showSnackbar", {
      message: "Изображение успешно загружено",
      color: "success",
    });
  }

  export const goodsAlreadyExistsInOrderSnackbar = ():void => {
    store.dispatch("snackbarService/showSnackbar", {
      message: "Этот товар уже добавлен в заказ",
      color: "error",
    });
  }



  export const debugErrorSnackbar = (e:any):void => {
    let message = ''
    if (e.response && e.response.data) {
      if (Array.isArray(e.response.data?.detail)){
        message = e.response.data.detail[0].msg
      }
      else{
        message = e.response.data.detail
      }
    } else if (e.message) {
      message = e.message
    
    } else {
      message = e
    }

    store.dispatch("snackbarService/showSnackbar", {
      message: "Произошла ошибка: " + message,
      color: "error",
    });
  }

  export const notAllRequiredFieldsSnackbar = (message:string):void => {
    store.dispatch("snackbarService/showSnackbar", {
      message: message,
      color: "success",
      timeout: 7000
    });
  }

  export const userAlreadyExistSnackbar = ():void => {
    store.dispatch("snackbarService/showSnackbar", {
      message: "Пользователь с таким логином уже существует",
      color: "warning",
    });
  }