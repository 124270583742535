import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { registerModules } from './register-modules'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'vuetify/styles'
import DatetimePicker from 'vuetify-datetime-picker'
import { vuetify } from './plugins/vuetify';
import bottomNavigationVue from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import Vue3TouchEvents from "vue3-touch-events";
import LoginModule from './modules/auth'
import OrderModule from './modules/order'
import CalendarModule from './modules/calendar'

registerModules({
    auth: LoginModule,
    order: OrderModule,
    calendar: CalendarModule,
})


createApp(App)
    .use(DatetimePicker)
    // @ts-ignore
    .use(bottomNavigationVue)
    .use(VueAxios, axios)
    .use(vuetify)
    .use(store)
    .use(router)
    .use(Vue3TouchEvents)
    .component('EasyDataTable', Vue3EasyDataTable)
    .provide('$vuetify', vuetify)
    .mount('#app')
