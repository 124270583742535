
import { API } from '@/api/api';
import { Client } from '@/types/clients'; 
import { debugErrorSnackbar } from '@/utils/snackbars';
const api = new API();


interface State {
    clients: {clients:Client[], page_number: number | null, pages_count : number | null},
    loading: boolean
}
interface Context {
    state: State;
    commit: (mutation: string, payload?: any) => void;
    dispatch: (action: string, payload?: any) => any;
}

const initialState:State  = {
    clients: {clients:[], page_number: null, pages_count : null},
    loading: false
}
export const clientsService = {
    namespaced: true,
    state: initialState,
    mutations: {
        setClients(state:State, payload: any) {
            state.clients = payload;
        },
        setLoading(state:State, loading: boolean) {
            state.loading = loading;
        },
        addClientToList(state:State, payload: Client) {
            state.clients.clients.push(payload);
        }
    },
    actions: {
        async getClients({commit}:Context,payload:any = {page_number:1, filter:null, page_limit:30}) {
            commit('setLoading', true);
            try {
                const response = await api.getClientsList({page_limit:30, page_number:payload.page_number, filter:payload.filter });
                if (response) {
                    commit('setClients', response);
                    commit('setLoading', false);
                    return {status:"success", data:response};
                }
            } catch (error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw {status:"error", message:error};
            }
        },     
        async getClientInfo({commit}:Context, payload:any) {
            return new Promise((resolve, reject) => {
                api.getClientInfo(payload).then((response: Client) => {
                    if (response) {
                        resolve(response);
                    }
                }).catch((error: any) => {
                    reject(null);
                })
            });
        },
        async createClient({commit}:Context, payload: Client) {
                return new Promise((resolve, reject) => {
                    api.addClient(payload).then((response: Client) => {
                        if (response) {
                            commit('addClientToList', response)
                            resolve({status:"success", data:response});
                        }else{
                            reject({status:"error", message:"Проверьте правильность введения номера, возможно клиент с таким номером уже существует"});
                        }
                    }).catch((error) => {
                        debugErrorSnackbar(error);
                        reject({status:"error", message:error});
                    });
                });
           
        },
        async editClient({commit}:Context, payload: Client) {
            try{
                return new Promise((resolve, reject) => {
                    api.editClient(payload).then((response: Client) => {
                        if (response) {
                            resolve({status:"success", data:response});
                        }
                    }).catch((error) => {
                        reject({status:"error", message:error});
                    });
                });
            }
            catch(error) {
                commit('setLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
    },
    getters: {
        selectClients: (state:State) => state.clients.clients,
        currentPage: (state:State) => state.clients.page_number,
        pagesCount: (state:State) => state.clients.pages_count,
        selectClientById: (state:State) => (id: number) => {
            return state.clients.clients.find((client: Client) => client.id === id);
        },
        loading: (state:State) => state.loading,
    },
}