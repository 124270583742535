
import { API } from '../api/api';
import { DiscountPayload, Order } from '@/types/orders';
import { debugErrorSnackbar } from '@/utils/snackbars';
// TODO: move to config
const errorCodes = [400,403]
const moduleApi = new API();
interface State {
    orders: any[] | null;
    orders_page: number;
    orders_pages: number;
    orderInfo: Order | null;
    ordersLoading: boolean;
    linkedServices: any[];
    servicesLoading: boolean,
    updateInterval: number | null;
    updateIntervalTime: number;
    orderDataLoading: boolean;
}

interface Context {
    state: State;
    rootGetters: any;
    commit: (mutation: string, payload?: any) => void;
    dispatch: (action: string, payload?: any) => any;
}
const initialState: State = {
    orders: null,
    orders_page: 1,
    orders_pages: 1,
    orderDataLoading: false,
    ordersLoading: false,
    orderInfo: null,
    linkedServices: [],
    servicesLoading: false,
    updateInterval: null,
    updateIntervalTime: 45000
}
export default {
    namespaced: true,
    state: initialState,
    mutations: {
        setOrderInfo: (state: State, payload: any) => state.orderInfo = payload,
        setOrdersLoading: (state: State, loading: boolean) => state.ordersLoading = loading,
        setOrderDataLoading: (state: State, loading: boolean) => state.orderDataLoading = loading,
        setservicesLoading: (state: State, loading: boolean) => state.servicesLoading = loading,
        setlinkedServices: (state: State, payload: any[]) => state.linkedServices = payload,
        addItemTolinkedServices: (state: State, payload: any) => state.linkedServices.push(payload),
        setOrders(state: State, payload: any) {
            state.orders = payload.orders as any[];
            state.orders_page = payload.page_number;
            state.orders_pages = payload.pages_count;
        },
        setGoodsItemQuantity(state: State, payload: any) {
            const { service_id, quantity } = payload;
            const linkedServices = state.linkedServices;
            const item = linkedServices.find((item: any) => item.service_id == service_id);
            if (item) {
                item.quantity = quantity;
            }
        },
    },
    actions: {
        // clean to initial state
        clean({ state, commit }: Context) {
            commit('setOrders', []);
            commit('setlinkedServices', []);
            if (state.updateInterval) {
                clearInterval(state.updateInterval);
            }
        },
        clearOrderInfo({ commit }: Context) {
            commit('setlinkedServices', []);
            commit('setOrderInfo', null);
        },
        async loadOrderData({ commit, dispatch }: Context, order_id: number | string | null = null) {
            try {
                commit('setOrderDataLoading', true);
                const res = await dispatch('getOrderInfo', order_id);
                if (order_id) {
                    await dispatch('getLinkedServices', order_id);
                }
                commit('setOrderDataLoading', false);
                return res;
            } catch (error) {
                commit('setOrderDataLoading', false);
                debugErrorSnackbar(error);
            }
        },
        async getOrderInfo({ commit }: Context, order_id: number | null = null) {
            try {
                return new Promise((resolve, reject) => {
                    const resp = moduleApi.getOrderInfo(order_id);
                    resp.then((response: any) => {
                        if (response && errorCodes.includes(response.status)) {
                            reject({ status: "error", message: response?.data.detail || 'Ошибка сохранения товаров' });
                            return
                        }
                        commit('setOrderInfo', response);
                        resolve({ status: "success", data: response });
                    }).catch((error: any) => {
                        reject({ status: "error", message: error });
                    });
                });
            } catch (error) {
                debugErrorSnackbar(error);
            }
        },

        // if array not empty - booking for this time is not available
        async checkAvailability({ commit}: Context, payload: any) {
            try {
                return new Promise((resolve, reject) => {
                    const current_order_id = payload.order_id;
                    delete payload.order_id;
                    const resp = moduleApi.checkTimeAvailability(payload);
                    console.log(resp)
                    resp.then((response: any) => {
                        if (response && errorCodes.includes(response.status)) {
                            reject({ status: "error", message: response?.data.detail || 'Ошибка проверки доступности', data: { available: false } });
                            return
                        }
                        if (!response || !response.orders.length) resolve({ status: "success", data: { available: true } });
                        // not show current booking
                        const filtered = response.orders.filter((item: any) => item.id != current_order_id && item.status != "rejected");
                        if (filtered.length) {
                            resolve({ status: "success", data: { available: false, order_id: filtered[0].id, order_status: filtered[0].is_reserved ? "reserved" : "booked" }, });
                        }
                        else {
                            resolve({ status: "success", data: { available: true } });
                        }
                    }).catch((error: any) => {
                        debugger
                        debugErrorSnackbar(error);
                        reject({ status: "error", message: error, data: { available: false } });
                    });
                })
            } catch (error: any) {
                debugger
                debugErrorSnackbar(error);
            }
        },
        async getOrdersDataForCalendar({ commit, state }: Context, payload: { order_statuses: any[] | null, reservation_begin: string | null, reservation_end: string | null, page_number: 1, page_limit: 1000 }) {
            return moduleApi.getOrdersListForCalendar(payload?.order_statuses, payload?.reservation_begin, payload?.reservation_end, payload?.page_number, payload?.page_limit)
        },
        // request orders list and check if data is changed(for not update )
        async ordersRequestSet({ commit, state, rootGetters, dispatch }: Context, payload: { office_id?: number, loft_id?: number, client_id?: number, responsible_id?: number, order_statuses: any[] | null, value?: string,period_days: number | null , page_number: 1, page_limit: 25 }) {
            try {
                const resp = moduleApi.getOrdersList(payload?.office_id, payload?.loft_id, payload?.order_statuses, payload?.client_id, payload?.responsible_id,  payload?.value, payload?.period_days, payload?.page_number, payload?.page_limit)
                resp.then((response: any) => {
                    if (response) {
                        // check if orders quantity is changed
                        if (state.orders == null || state.orders.length !== response.length) {
                            commit('setOrders', response);
                        } else {
                            // check if orders data is changed using json stringify
                            const orders = JSON.stringify(state.orders);
                            const newOrders = JSON.stringify(response.orders);
                            if (orders !== newOrders) {
                                commit('setOrders', response);
                            }
                        }
                        commit('setOrdersLoading', false);
                    }
                }).catch((error: any) => {
                    commit('setOrdersLoading', false);
                    debugErrorSnackbar(error);
                    throw error;
                });
            } catch (error: any) {
                commit('setOrdersLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async getLinkedServices({ commit }: Context, order_id: number) {
            try {
                if (!order_id) return;
                commit('setservicesLoading', true);
                const resp = await moduleApi.getOrderGoods(order_id);
                if (resp) {
                    commit('setservicesLoading', false);
                    commit('setlinkedServices', resp);
                    return { status: "success", data: resp };
                }
                commit('setservicesLoading', false);
                return { status: "error", message: resp };
            } catch (error) {
                commit('setservicesLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async setOrderDiscount({ dispatch }: Context, payload: DiscountPayload) {
            try {
                const resp = await moduleApi.setOrderDiscount(payload).finally(() => {
                    dispatch("getOrderInfo", payload.order_id)
                });
                if (resp) {
                    return { status: "success", data: resp };
                }
                return { status: "error", message: resp };
            } catch (error) {
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async deleteOrderDiscount({ dispatch }: Context, payload: { order_id: number, record_id: number }) {
            try {
                const resp = await moduleApi.deleteOrderDiscount(payload.record_id).finally(() => {
                    dispatch("getOrderInfo", payload.order_id)
                });
                if (resp) {
                    return { status: "success", data: resp };
                }
                return { status: "error", message: resp };
            } catch (error) {
                debugErrorSnackbar(error);
                throw error;
            }
        },

        async changeGoodsQuantity({ commit }: Context, payload: { goods_id: number, quantity: number }) {
            if (payload.quantity >= 0) {
                commit('setGoodsItemQuantity', payload);
            }
        },
        async addServiceToOrder({ state, commit }: Context, payload: { service_id: number, quantity: number }) {
            const linkedServices = state.linkedServices;
            const item = linkedServices.find((item: any) => item.service_id === payload.service_id);
            if (!item) {
                commit('addItemTolinkedServices', payload);
                return { status: "success", data: payload };
            }
            return { status: "error", message: 'item already added' };
        },

        // add goods to order and save it to db
        async saveOrderGoods({ commit }: Context, payload: { data: any[], order_id: number }) {
            try {
                return new Promise((resolve, reject) => {
                    commit('setservicesLoading', true);
                    const response = moduleApi.addOrderGoods(payload.data, payload.order_id) as any
                    if (response && errorCodes.includes(response.status)) {
                        reject({ status: "error", message: response?.data.detail || 'Ошибка сохранения товаров' });
                        return
                    }
                    commit('setservicesLoading', false);
                    resolve({ status: "success", data: response });
                });
            } catch (error) {
                commit('setservicesLoading', false);
                debugErrorSnackbar(error);
                throw error;
            }
        },
        // periodicaly request new orders from server
        async getOrders({ dispatch, state, rootGetters }: Context, payload: { office_id?: number, loft_id?: number, order_statuses?: any[] | null, client_id?: number,period_days?: number | null, page_number: 1, page_limit: 25 }) {
            if (state.updateInterval) {
                clearInterval(state.updateInterval);
            }
            await dispatch('ordersRequestSet', payload);
            state.updateInterval = setInterval(() => {
                if (rootGetters.isAutoUpdateInterupted) {
                    console.log('auto update interupted')
                    return;
                }
                dispatch('ordersRequestSet', payload);
            }, state.updateIntervalTime)
        },
        // create new order
        async createOrder({ dispatch }: Context, payload: Order) {
            try {
                return new Promise((resolve, reject) => {
                    const resp = moduleApi.createOrder(payload);
                    resp.then((response: any) => {
                        if (response && errorCodes.includes(response.status)) {
                            reject({ status: "error", message: response?.data.detail || 'Ошибка создания ордера' });
                            return
                        }
                        dispatch('ordersRequestSet');
                        resolve({ status: "success", data: response });
                    }).catch((error: any) => {
                        debugErrorSnackbar(error);
                        reject(error);
                    }
                    );
                })
            } catch (error: any) {
                debugErrorSnackbar(error);
                return
            }
        },
        // edit existing order
        // Edit existing order
        async editOrder({ dispatch }: Context, payload: Order) {
            try {
                const response = await moduleApi.editOrder(payload);
                if (response && errorCodes.includes(response.status)) {
                    throw new Error(response?.data.detail || 'Ошибка изменения ордера');
                }
                await dispatch('getOrders', {});
                await dispatch('getOrderInfo', payload.id);
                return { status: "success", data: response };
            } catch (error:any) {
                debugErrorSnackbar(error);
                // Re-throwing the error or handling it as needed
                throw new Error(error?.message || 'Error editing order');
            }
        },

        // Get order history
async getOrderHistory({ commit }: Context, order_id: number) {
    try {
        const response = await moduleApi.getOrderHistory(order_id);

        if (response && errorCodes.includes(response.status)) {
            // Handle specific status code, if necessary
            throw new Error(response?.data.detail || 'Ошибка изменения ордера');
        }

        return { status: "success", data: response };
    } catch (error:any) {
        debugErrorSnackbar(error);
        // Re-throwing the error or handling it as needed
        throw new Error(error?.message || 'Error fetching order history');
    }
},

        async getOrderReceipt({commit }: Context, order_id: number) {
            try {
                return new Promise((resolve, reject) => {
                    moduleApi.getOrderReceipt(order_id).then((response: any) => {
                        if (response && errorCodes.includes(response.status)) {
                            reject({ status: "error", message: response?.data.detail || 'Ошибка изменения ордера' });
                            return
                        }
                        resolve({ status: "success", data: response });

                    }).catch((error: any) => {
                        debugErrorSnackbar(error);
                        reject(error);
                    });
                });
            } catch (error) {
                debugErrorSnackbar(error);
                throw error;
            }
        },
        async regenerateOrderReceipt({ commit}: Context, order_id: number) {
            try {
                return new Promise((resolve, reject) => {
                    moduleApi.regenerateReceipt(order_id).then((response: any) => {
                        if (response && errorCodes.includes(response.status)) {
                            reject({ status: "error", message: response?.data.detail || 'Ошибка изменения ордера' });
                            return
                        }
                        resolve({ status: "success", data: response });

                    }).catch((error: any) => {
                        debugErrorSnackbar(error);
                        reject(error);
                    });
                });
            } catch (error) {
                debugErrorSnackbar(error);
                throw error;
            }
        }
    },

    getters: {
        selectOrderAditionalGoods: (state: State) => state.linkedServices,
        selectOrders: (state: State) => state.orders,
        selectOrdersPage: (state: State) => state.orders_page,
        selectOrdersPages: (state: State) => state.orders_pages,
        selectOrderInfo: (state: State) => state.orderInfo,
        selectOrderById: (state: State) => (id: number) => state.orders ? state.orders.find((order: any) => order.id == id) : [],
        selectOrdersLoading: (state: State) => state.ordersLoading,
        servicesLoading: (state: State) => state.servicesLoading,
        orderDataLoading: (state: State) => state.orderDataLoading,
        newNotBookedOrders: (state: State) => state.orders ? state.orders.filter((order: any) => order.reservation_begin == null && order.status == 'created') : [],
    },
}