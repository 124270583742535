import router from "./router";
import store from "./store";

type Module = {
    router?: (router: any) => void;
    store?: any;
}

const registerModule = (name: string, module: Module) => {
    if (module.router) {
        module.router(router);
    }
    if (module.store) {
        store.registerModule(name, module.store);
    }
}

export const registerModules = (modules: { [key: string]: Module }) => {
    Object.keys(modules).forEach((moduleKey) => {
        const module = modules[moduleKey];
        registerModule(moduleKey, module);
    });
}