
const  loginRoute =  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ './view/LoginPage.vue'),
    meta: {
      requiresAuth: false,
      pageName: 'Вход'
    }
  }

  export default (router: { addRoute: (arg0: { path: string; name: string; component: () => Promise<typeof import("*.vue")>; meta: { requiresAuth: boolean; pageName: string } }) => void }) => {
    router.addRoute(loginRoute)
  }