const calendarRoute = {
    path: '/calendar',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "calendar" */ './view/CalendarPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Календарь событий',
      permission: null
    }
  }
  
  export default (router: { addRoute: (arg0: { path: string; name: string; component: () => Promise<typeof import("*.vue")>; meta: { requiresAuth: boolean; pageName: string, permission:any} }) => void }) => {
    router.addRoute(calendarRoute)
  }