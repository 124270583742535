import store from '../index';

import { Shift } from '@/types/shift';
import { debugErrorSnackbar } from '@/utils/snackbars';
import { API } from '@/api/api';
const api = new API();
interface InitialState {
    shift: Shift | null,
    shifts: Shift[],
    interval: number | null,
    requestInterval: number,
    loading: boolean,
    shiftInfoLoading: boolean
}
interface Context {
    rootGetters: any;
    state: InitialState;
    commit: (mutation: string, payload?: any) => void;
    dispatch: (action: string, payload?: any) => any;
}
const initialState: InitialState = {
    shift: null,
    shifts: [],
    interval: null,
    requestInterval: 5000,
    loading: false,
    shiftInfoLoading: false
}
export const shiftService = {
    namespaced: true,
    state: initialState,
    mutations: {
        setShiftStatus(state: any, payload: boolean) {
            state.shift = payload;
        },
        setShiftInfoLoading(state: any, loading: boolean) {
            state.shiftInfoLoading = loading;
        },
        setLoading(state: any, loading: boolean) {
            state.loading = loading;
        },
        setNewResponsible(state: any, responsible_id: number) {
            if (state.shift) {
                state.shift.responsible_id = responsible_id;
            }
        },
        setShifts(state: any, payload: Shift[]) {
            state.shifts = payload;
        }
    },
    actions: {
        async clean({ state, commit }: Context) {
            commit('setShiftStatus', null);
            commit('setShifts', []);
            if (state.interval) {
                clearInterval(state.interval);
            }
        },
        async getShifts({ commit, rootGetters }: Context, payload: any = { open_only: false }) {
            try {
                commit('setLoading', true)
                // get user_id from global state
                const response = await api.getShifts(payload) as any;
                if (response) {
                    commit('setShifts', response);
                } else {
                    commit('setShifts', []);
                }
                commit('setLoading', false)
            } catch (e) {
                debugErrorSnackbar(e);
                commit('setLoading', false)
                return { status: 'error', message: e }
            }
        },
        // get orders for selected shift - for report
        async getShiftOrders({ commit }: Context, shift_id: number) {
            try {
                // get user_id from global state
                const response = await api.getShiftOrders(shift_id) as any;
                if (response) {
                    return { status: 'success', data: response }
                }
                return { status: 'error', message: response }
            }
            catch (e) {
                debugErrorSnackbar(e);
                commit('setLoading', false)
                return { status: 'error', message: e }
            }
        },
        async getShiftRequest({ commit, rootGetters }: Context) {
            try{
                const user = rootGetters['auth/selectUser'];
                if (user && user.office_id) {
                    const response = await api.getShift(user.office_id);
                    if (response) {
                        commit('setShiftStatus', response);
    
                    } else {
                        commit('setShiftStatus', null);
                    }
                }else{
                    commit('setShiftStatus', null);
                }
                commit('setShiftInfoLoading', false)

            }catch(e){
                commit('setShiftInfoLoading', false)
            }
        },
        async getShift({ state, commit, dispatch }: Context) {
            commit('setShiftInfoLoading', true)
            // get user_id from global state
            dispatch('getShiftRequest')
            if (state.interval) {
                clearInterval(state.interval);
            }
            state.interval = setInterval(async () => {
                dispatch('getShiftRequest')
            }, state.requestInterval);

        },
        async openShift({ dispatch }: Context) {
            try {
                const resp = await api.openShift();
                if (resp) {
                    dispatch('getShifts');
                    return { status: 'success', data: resp }
                }
                return { status: 'error', message: resp }
            } catch (e) {
                debugErrorSnackbar(e);
                return { status: 'error', message: e }
            }
        },
        async closeShift({ dispatch }: Context) {
            try {
                const resp = await api.closeShift();
                if (resp) {
                    dispatch('getShifts');
                    return { status: 'success', data: resp }
                }
                return { status: 'error', message: resp }
            } catch (e) {
                debugErrorSnackbar(e);
                return { status: 'error', message: e }
            }
        },
        async transferShift({ state, commit, rootGetters }: Context, responsible_id: number) {
            try {
                commit('setNewResponsible', responsible_id);
                if (state.shift) {
                    const resp = await api.transferShift(state.shift);
                    if (resp) {
                        return { status: 'success', data: resp }
                    }
                    return { status: 'error', message: resp }
                }
            } catch (e) {
                debugErrorSnackbar(e);
                return { status: 'error', message: e }
            }
        }


    },
    getters: {
        selectShift: (state: any) => state.shift,
        selectShifts: (state: any) => state.shifts,
        selectShiftsByOffice: (state: InitialState) => (office_id: number) => {
            return state.shifts.filter((shift: Shift) => shift.office_id === office_id);
        },
        selectShiftInfoLoading: (state: InitialState) => state.shiftInfoLoading,
        loading: (state: InitialState) => state.loading,
        selectShiftStatus: (state: InitialState) => state.shift && state.shift.is_open ? state.shift.is_open : false,

    }
}