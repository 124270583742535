import { Order } from "@/types/orders";
export const isMobile = ():boolean => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const formatRuPhoneNumber = (num: number | string): string => {
    if (!num || num === undefined) return "Не указан";
    const str = num.toString();
    const prefix = str.slice(0, 1);
    const first = str.slice(1, 4);
    const second = str.slice(4, 7);
    const third = str.slice(7, 9);
    const fourth = str.slice(9, 11);
    return `+${prefix} (${first}) ${second}-${third}-${fourth}`;
  };
  export const formatRuPhoneNumberdigits = (num: number | string): string => {
    if (!num || num === undefined) return "Не указан";
    const str = num.toString();
    const prefix = str.slice(0, 1);
    const first = str.slice(1, 4);
    const second = str.slice(4, 7);
    const third = str.slice(7, 9);
    const fourth = str.slice(9, 11);
    return `${prefix} ${first} ${second} ${third} ${fourth}`;
  };


  export const formatTimeHHMM = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`
}
 // mark order as locked
export const orderIsLocked = (order: Order):boolean => {
    return (order.status in ["paid", "working", "completed"]);
  }

export const formatRuDate = (date: Date) => {
    const options:any = {
      weekday: "short",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(date).toLocaleString("ru", options);
  };

export const debounce = (func: any, wait: number, immediate=false) => {
    let timeout: any;
    return function executedFunction() {
      const later = function () {
        timeout = null;
              // @ts-ignore
        if (!immediate) func.apply(this, rest);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      // @ts-ignore
      if (callNow) func.apply(this, rest);
    };
  }