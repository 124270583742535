import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { LocalStorage } from '../utils/localstorage'
import store  from '../store'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomePage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Заказы',
      permission: null
    }
  },
  
  {
    path: '/lofts',
    name: 'lofts',
    component: () => import(/* webpackChunkName: "lofts" */ '../views/LoftsPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Лофты',
      permission: 'create_lofts'
    }
  },
  {
    path: '/prices',
    name: 'prices',
    component: () => import(/* webpackChunkName: "lofts" */ '../views/PricesPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Цены',
      permission: ''
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/UsersPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Сотрудники',
      permission: null
    }
  },
  {
    path: '/users/:id',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '../views/UserPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Карта сотрудника',
      showBackButton: true,
      permission: null
    }
  },
  {
    path: '/offices',
    name: 'offices',
    component: () => import(/* webpackChunkName: "offices" */ '../views/OfficesPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Заведения',
      permission: 'create_offices'
    }
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "clients" */ '../views/ClientsPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Клиенты',
      permission: 'create_clients'
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '../views/NotificationsPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Уведомления',
      permission: null
    }
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "services" */ '../views/GoodsPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Товары',
      permission: 'create_services'
    }
  },
  
  {
    path: '/shifts',
    name: 'shifts',
    component: () => import(/* webpackChunkName: "shifts" */ '../views/ShiftsPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Рабочие смены',
      permission: null
    }
  },
  {
    path: '/shifts/report/:id',
    name: 'shiftreport',
    component: () => import(/* webpackChunkName: "shifts" */ '../views/ShiftReportPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Отчет по смене',
      showBackButton: true,
      permission: null
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/NotFoundPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Страница не найдена',
      permission: null
    }
  },
  {
    path: '/nopermission',
    name: 'nopermission',
    component: () => import(/* webpackChunkName: "nopermissions" */ '../views/NoPermissionPage.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Нет прав доступа',
      permission: null,
      showBackButton: true
    }
  },
  {
    path: '/pricenet/:id',
    name: 'pricenet',
    component: () => import(/* webpackChunkName: "nopermissions" */ '../views/PriceNet.vue'),
    meta: {
      requiresAuth: true,
      pageName: 'Тарифная сетка',
      permission: null,
      showBackButton: true
    }
  },
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = LocalStorage.get('token')
  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    const pagePermissions = to.meta.permission
    if (!pagePermissions){
      next()
    }else{
      const userPermissions = store.getters["auth/selectUserPermissions"]
      // if user data not yet loading
      if (!userPermissions){
        next('/home')
      }
      else if (userPermissions.includes(pagePermissions)){
        next()
      }else{
        next('/nopermission')
      }
    }
  }
})

export default router
